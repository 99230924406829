<template>
	<PopupTwoCol name="vgo"
	text="<h3>Projet HDT de VGO <br/>(Hydrotraitement de Vacuum GasOil)</h3><br/><p>Il s’agit d’une unité de désulfuration des charges intermédiaires qui permettra de produire des carburants moins soufrés et conformes aux spécifications Européennes.</p>"
	video="static/video/vgo.mp4"
	sticker="static/logo-total.jpg"
	navigation="true">
</PopupTwoCol>
</template>

<script>
	export default {
		components: {
			PopupTwoCol: () => import('@/components/PopupTwoCol.vue'),
		},
	}
</script>

<style lang="scss">

// ---------------------------------------------------------------------------
// Navigation
// ---------------------------------------------------------------------------

@mixin navigation-popup($position, $color) {
  nav {
    ul {
      li {
        display: inline-block;
        &:nth-child(#{$position}) {
          span {
            position: relative;
            background-color: var(--color-white);
            padding: .25rem;
            color: #{$color};
            font-weight: 700;
            border-radius: 50px;
            &:before {
              content: "#{$position}";
              position: absolute;
              background: transparent url(../../assets/media/icons/cursor.svg) center no-repeat;
              width: 19.2px;
              height: 23.2px;
              background-size: contain;
              color: #{$color};
              text-align: center;
              top: -19px;
              left: 50%;
              -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
              z-index: 9;
              font-size: 1.25em;
              line-height: 2;
              @media #{$mq-xm} {
                width: 19.2px;
                height: 23.2px;
                top: -19px;
              }
              @media #{$mq-large} {
                width: 22.9px;
                height: 26.9px;
                top: -23px;
                line-height: 2;
              }
              @media #{$mq-xlarge} {
                width: 32.9px;
                height: 40.9px;
                top: -33px;
              }
              @media #{$mq-xxlarge} {
                width: 47.8px;
                height: 59.8px;
                top: -52px;
              }
            }
            @media #{$mq-xm} {
              padding: .25rem .5rem;
            }
            @media #{$mq-large} {
              padding: .25rem .75rem;
            }
            @media #{$mq-xlarge} {
              padding: .45rem 1rem;
            }
            @media #{$mq-xxlarge} {
              padding: .5rem 1rem;
            }
          }
        }
      }
    }
  }
}

.vgo {
	@include navigation-popup(2, var(--color-blue));
	.navigation {
		height: 65px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1rem;
		background-color: var(--color-blue);
		overflow: hidden;
		border-radius: 10px;
		color: var(--color-white);
    @media #{$mq-small} {
      
    }
    @media #{$mq-xm} {
      height: 65px;
    }
    @media #{$mq-medium} {
      height: 65px;
      border-radius: 10px;
    }
    @media #{$mq-large} {
      height: 75px;
      border-radius: 10px;
    }
    @media #{$mq-xlarge} {
      height: 125px;
      border-radius: 35px;
    }
    @media #{$mq-xxlarge} {
      height: 185px;
      border-radius: 45px;
    }
    @media #{$mq-xxxlarge} {
      height: 185px;
    }
    @media #{$mq-xxxxlarge} {
      height: 195px;
    }
	}
}

</style>
